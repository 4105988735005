import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';

const req = ApiUtil.req;

const ContactSection = (props) => {
  const [formData, setFormData] = React.useState({});

  const sendData = async () => {
    let msg = null;

    do {
      if (!formData.name) {
        msg = props.lang === 'tw' ? '請輸入您的姓名' : 'Please enter your name';
        break;
      }

      if (!formData.phone) {
        msg =
          props.lang === 'tw'
            ? '請輸入您的手機號碼'
            : 'Please enter your phone';
        break;
      }

      if (!formData.email) {
        msg =
          props.lang === 'tw'
            ? '請輸入您的電子信箱'
            : 'Please enter your email';
        break;
      }

      if (!formData.body) {
        msg =
          props.lang === 'tw'
            ? '請輸入詢問內容'
            : 'Please enter your requirement';
        break;
      }

      console.log('formData', formData);
      const {name, phone, email, body} = formData;
      try {
        await req(
          'https://jstorage.revtel-api.com/v2/document/contact/create?client_id=realit',
          {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'Content-Type': 'application/json',
            },
            data: {
              data: {
                name,
                phone,
                email,
                body,
              },
            },
          },
        );
      } catch (err) {
        console.err(err);
      }

      setFormData({name: '', phone: '', email: '', body: ''});
    } while (0);

    msg && window.alert(msg);
  };

  return (
    <Wrapper id="contact">
      <div className="content">
        <Title>
          <div
            style={{
              width: 80,
              height: 30,
              backgroundColor: '#01fbba',
              borderRadius: 5,
            }}
          />
          <h1 style={{marginTop: 40}}>
            {props.lang === 'tw' ? '聯絡我們' : 'Contact Us'}
          </h1>
        </Title>
        <Form>
          <Input
            top
            placeholder={props.lang === 'tw' ? '您的姓名' : 'Name'}
            value={formData.name}
            onChange={(evt) =>
              setFormData({...formData, name: evt.target.value})
            }
          />
          <Input
            placeholder={props.lang === 'tw' ? '手機號碼' : 'Phone'}
            value={formData.phone}
            onChange={(evt) =>
              setFormData({...formData, phone: evt.target.value})
            }
          />
          <Input
            placeholder={props.lang === 'tw' ? '電子信箱' : 'Email'}
            value={formData.email}
            onChange={(evt) =>
              setFormData({...formData, email: evt.target.value})
            }
          />
          <InputTextarea
            placeholder={props.lang === 'tw' ? '詢問內容' : 'Requirement'}
            value={formData.body}
            onChange={(evt) =>
              setFormData({...formData, body: evt.target.value})
            }
          />

          <div
            style={{
              cursor: 'pointer',
              marginTop: 30,
              borderRadius: 5,
              width: '100%',
              backgroundColor: '#00FBBA',
              height: 40,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={sendData}>
            {props.lang === 'tw' ? '確認送出' : 'Send'}
          </div>
        </Form>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  padding-top: 120px;

  display: flex;
  flex-direction: row;
  justify-content: center;

  padding-left: 20px;
  padding-right: 20px;

  & .content {
    width: 100%;
    max-width: 1200px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    flex-wrap: wrap;
  }
`;

const Title = styled.div`
  width: 200px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Form = styled.div`
  flex: 1;
  min-width: 250px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Input = styled.input`
  margin-top: ${(props) => (props.top ? '0px' : '25px')};

  background-color: #ededed;
  border-color: #ededed;
  border-width: 0px;

  padding-left: 10px;

  width: 100%;
  height: 40px;
`;

const InputTextarea = styled.textarea`
  margin-top: ${(props) => (props.top ? '0px' : '25px')};

  background-color: #ededed;
  border-color: #ededed;
  border-width: 0px;

  padding-left: 10px;

  width: 100%;
  height: 100px;
`;

export default ContactSection;
