import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

const InfoSections = (props) => {
  return (
    <Wrapper>
      <div className="content">
        {/* 1 */}
        <InfoSection reverse>
          <div className="content">
            <div
              style={{
                width: 60,
                height: 20,
                backgroundColor: '#0038ff',
                borderRadius: 5,
              }}
            />

            <h2 style={{marginTop: 20}}>Android/iOS APP</h2>
            <h3 style={{marginTop: 20}}>An app communicate with tags</h3>

            <p style={{marginTop: 20}}>
              ・
              {`${
                props.lang === 'tw'
                  ? '簽章加密技術唯一識別 RealiTags'
                  : 'Unique Identification of RealiTags through Signature Encryption Technology'
              }`}
            </p>
            <p style={{marginTop: 20}}>
              ・
              {props.lang === 'tw'
                ? 'Android、IOS雙平台支援'
                : 'Support for both Android and iOS platforms'}
            </p>
            <p style={{marginTop: 20}}>
              ・
              {props.lang === 'tw'
                ? 'Universal Link 隨掃即得'
                : 'Instant access through Universal Links'}
            </p>

            <div style={{marginTop: 30, display: 'flex', flexDirection: 'row'}}>
              <img
                src="/android-donwload.png"
                width="40"
                height="40"
                style={{cursor: 'pointer'}}
              />
              <img
                src="/ios-download.png"
                width="40"
                height="40"
                style={{cursor: 'pointer', marginLeft: 30}}
              />
            </div>
          </div>
          <div className="img">
            <img src="/info1.webp" style={{width: '100%', minWidth: 350}} />
          </div>
        </InfoSection>

        {/* 2 */}
        <InfoSection style={{marginTop: 40}}>
          <div className="content">
            <div
              style={{
                width: 60,
                height: 20,
                backgroundColor: '#0038ff',
                borderRadius: 5,
              }}
            />

            <h2 style={{marginTop: 20}}>
              {props.lang === 'tw'
                ? '權限管理標籤'
                : 'Permission Management Tags'}
            </h2>
            <h3 style={{marginTop: 20}}>Authority management tag</h3>

            <p style={{marginTop: 20}}>
              ・{props.lang === 'tw' ? '可重複使用' : 'Reusable'}
            </p>
            <p style={{marginTop: 20}}>
              ・{props.lang === 'tw' ? '分群組管理' : 'Group-based management'}
            </p>
            <p style={{marginTop: 20}}>
              ・{props.lang === 'tw' ? '成本最佳化' : 'Cost optimization'}
            </p>
          </div>
          <div className="img">
            <img
              src="/previledge.webp"
              style={{width: '100%', minWidth: 350}}
            />
          </div>
        </InfoSection>

        {/* 3 */}
        <InfoSection style={{marginTop: 40}} reverse>
          <div className="content">
            <div
              style={{
                width: 60,
                height: 20,
                backgroundColor: '#0038ff',
                borderRadius: 5,
              }}
            />

            <h2 style={{marginTop: 20}}>
              {props.lang === 'tw' ? '防偽標籤' : 'Anti-counterfeiting labels'}
            </h2>
            <h3 style={{marginTop: 20}}>Ani-counterfeiting tag</h3>

            <p style={{marginTop: 20}}>
              ・{' '}
              {props.lang === 'tw'
                ? '自訂兩種重新導向URL'
                : 'Customize two types of redirect URLs'}
            </p>
            <p style={{marginTop: 20}}>
              ・ {props.lang === 'tw' ? '拆封印記' : 'Tamper-evident seal'}
            </p>
            <p style={{marginTop: 20}}>
              ・{' '}
              {props.lang === 'tw'
                ? '非對稱式加密杜絕仿冒'
                : 'Asymmetric encryption prevents counterfeiting'}
            </p>
          </div>
          <div className="img">
            <img src="/anti-fake.webp" style={{width: '100%', minWidth: 350}} />
          </div>
        </InfoSection>

        {/* 4 */}
        <InfoSection style={{marginTop: 40}}>
          <div className="content">
            <div
              style={{
                width: 60,
                height: 20,
                backgroundColor: '#0038ff',
                borderRadius: 5,
              }}
            />

            <h2 style={{marginTop: 20}}>
              {props.lang === 'tw'
                ? 'NFT虛實整合'
                : 'NFT integration of virtual and physical assets'}
            </h2>
            <h3 style={{marginTop: 20}}>Welcome to WEB3</h3>

            <p style={{marginTop: 20}}>
              ・ {props.lang === 'tw' ? '密碼保護' : 'Password protection'}
            </p>
            <p style={{marginTop: 20}}>
              ・ {props.lang === 'tw' ? '容易鑄造' : 'Easy forgery'}
            </p>
            <p style={{marginTop: 20}}>
              ・ {props.lang === 'tw' ? '拆封註記' : 'Unsealing record'}
            </p>
            <p style={{marginTop: 20}}>
              ・{' '}
              {props.lang === 'tw'
                ? '支援法幣交易'
                : 'Support for fiat currency transactions'}
            </p>

            <div
              style={{
                marginTop: 30,
                display: 'flex',
                padding: 5,
                borderRadius: 5,
                backgroundColor: '#00FBBA',
                cursor: 'pointer',
              }}
              onClick={() => window.open('https://nft.revtel.tech/')}>
              {props.lang === 'tw' ? '了解更多 NFT 開發' : 'More'}
            </div>
          </div>
          <div className="img">
            <img
              src="/nft-integration.webp"
              style={{width: '100%', minWidth: 350}}
            />
          </div>
        </InfoSection>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100vw;
  padding-right: min(50px, 10%);
  padding-left: min(50px, 10%);

  & .content {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 1200px;
  }
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  justify-content: center;
  align-items: flex-start;

  width: 100%;

  flex-wrap: wrap;

  & .img {
    display: flex;
    justify-content: center;
    align-items: center;

    flex: 1;
    max-width: 400px;
  }

  & .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 300px;

    margin-bottom: 40px;

    & h2 {
      font-size: 2em;
      font-weight: 500;
      font-family: Futura;
    }

    & h3 {
      font-size: 1.6em;
      font-weight: 500;
      font-family: Futura;
    }

    & p {
      font-family: HelveticaNeue;
      font-size: 1.2em;
    }
  }
`;

export default InfoSections;
