import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import YoutubeIframe from './YoutubeIframe';

const DeckSection = (props) => {
  return (
    <Wrapper id="intro">
      <div className="content">
        <div
          style={{width: '80%', maxWidth: 600, height: '100%', marginBo: 20}}>
          <YoutubeIframe videoId={'wZCA0JrYGRI'} videoTitle={''} />
        </div>
        {props.lang === 'tw' && (
          <a href="/RealiTIntro.pdf" target="_blank">
            <h2 style={{color: '#647E68', cursor: 'pointer'}}>產品投影片 ⇨</h2>
          </a>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100vw;
  padding-right: min(50px, 10%);
  padding-left: min(50px, 10%);
  padding-top: 30px;
  padding-bottom: 80px;

  & .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    max-width: 1200px;
  }
`;

export default DeckSection;
